import { useForm } from '@conform-to/react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { cssBundleHref } from '@remix-run/css-bundle';
import { type HeadersFunction, json, type LinksFunction, type LoaderFunctionArgs, type MetaFunction } from '@remix-run/node';
import { Form, Link, Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useFetcher, useFetchers, useLoaderData, useMatches } from '@remix-run/react';
import { Fragment } from 'react';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { z } from 'zod';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { EpicProgress } from './components/progress-bar.tsx';
import { useToast } from './components/toaster.tsx';
import { href as iconsHref, Icon } from './components/ui/icon.tsx';

import tailwindStyleSheetUrl from './styles/tailwind.css';
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx';
import { csrf } from './utils/csrf.server.ts';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { combineHeaders, getDomainUrl, isCurrentPage } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { useRequestInfo } from './utils/request-info.ts';
import { getTheme, setTheme, type Theme } from './utils/theme.server.ts';
import { makeTimings, time } from './utils/timing.server.ts';
import { getToast } from './utils/toast.server.ts';
import { useOptionalUser } from './utils/user.ts';
import classNames from 'classnames';
import { getMediaSizeCookie, getTaxReturnReadyWarningCookie, setMediaSizeCookie, setTaxReturnReadyWarning } from "#app/utils/media.server.ts";
import swiperStyle from 'swiper/swiper-bundle.min.css';
import { getFormProps } from '@conform-to/react';
import { get } from '#app/utils/refundr.server.aragorn.ts';
import type { Agent } from '#app/model/model.ts';
import { parseWithZod } from '@conform-to/zod';
import { invariantResponse } from '@epic-web/invariant';
import { ServerOnly } from 'remix-utils/server-only';
import { Toaster } from 'react-hot-toast';
import { getUserId, logout } from '#app/utils/session.server.ts';

const navigation = [
{ name: 'Dashboard', href: 'dashboard', current: true },
{ name: 'Tax Returns', href: 'returns', current: false },
{ name: 'Clients', href: 'clients', current: false },
{ name: 'AI Assistant', href: 'ai', current: false },
{ name: 'Invite', href: 'invite', current: false },
{ name: 'Payments', href: 'payments', current: false },
{ name: 'Settings', href: '/settings/account/account', current: false }];


export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/favicon.png' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/favicon.png' }, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/png', href: '/favicons/favicon.png' },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  {
    rel: 'stylesheet',
    href: swiperStyle
  },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: 'refundr' },
  {
    name: 'description',
    content: 'Passionately committed to better connecting tax consultants and their clients.'
  },
  { 'og:description': 'Passionately committed to better connecting tax consultants and their clients.' },
  { 'og:image': 'https://dashboard.refundr.ca/img/refundr.jpg' },
  { 'og:url': 'https://dashboard.refundr.ca' },
  { 'og:site': 'https://dashboard.refundr.ca' }];

};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader');
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  });

  const user = userId ?
  await time(() => get<Agent>(request, `/agents/${userId}?detail=simple`),
  { timings, type: 'find user', desc: 'find user in root' }
  ) :
  null;

  if (userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' });
  }

  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();
  const mediaSizeCookie = getMediaSizeCookie(request);
  const createdMediaSizeCookie = setMediaSizeCookie('xl');
  const taxReturnReadyStateCookie = getTaxReturnReadyWarningCookie(request);
  const createdTaxReturnReadyState = setTaxReturnReadyWarning('true');

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getEnv(),
      toast,
      honeyProps,
      csrfToken
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
        csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null,
        !mediaSizeCookie ? { 'set-cookie': createdMediaSizeCookie } : null,
        !taxReturnReadyStateCookie ? { 'set-cookie': createdTaxReturnReadyState } : null
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark'])
});

export async function action({ request }: LoaderFunctionArgs) {
  const formData = await request.formData();
  const submission = parseWithZod(formData, {
    schema: ThemeFormSchema
  });
  invariantResponse(submission.status === 'success', 'Invalid theme received');
  const { theme } = submission.value;

  const responseInit = {
    headers: { 'set-cookie': setTheme((theme as any)) }
  };
  return json({ success: true, submission }, responseInit);
}

function Document({
  children,
  nonce,
  theme = 'light',
  env = {}





}: {children: React.ReactNode;nonce: string;theme?: Theme;env?: Record<string, string>;}) {
  return (
    <html lang="en" className={`${theme} h-full overflow-x-hidden`} suppressHydrationWarning>
        <head>
            <ClientHintCheck nonce={nonce} />
            {/* Google tag (gtag.js) */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-14MH0TC1F0"></script>
            <script
          dangerouslySetInnerHTML={{
            __html: `
							window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments)}
							gtag('js', new Date()); 
							gtag('config', 'G-14MH0TC1F0');`
          }}>
        </script>
            <Meta />
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            {/*OG Meta Tags to improve the way the post looks when you share the page on LinkedIn, Facebook, Google+*/}
            <meta content="https://dashboard.refundr.ca" property="og:site" />
            <meta content="refundr" property="og:title" />
            <meta content="Tax return and client management software" property="og:description" />
            <meta content="https://dashboard.refundr.ca/img/refundr.jpg" property="og:image" />
            <meta content="https://dashboard.refundr.ca" property="og:url" />
            <Links />
        </head>
        <body className="bg-background text-foreground">
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <LiveReload nonce={nonce} />
        </body>
        </html>);

}

function App() {
  const matches = useMatches();
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const user = useOptionalUser();
  const theme = useTheme();
  // const isOnSearchPage = matches.find(m => m.id === 'routes/users+/index')
  // const searchBar = isOnSearchPage ? null : <SearchBar status="idle"/>
  useToast(data.toast);

  return (
    <Document nonce={nonce} theme={theme} env={data.ENV}>
            <div className="flex h-screen flex-col justify-between">
                <div className="min-h-full">
                    {/* NAV START */}
                    <Disclosure as="nav" className="border-b border-gray-200 bg-white shadow-sm dark:shadow-none">
                        {({ open }) =>
            <>
                                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                                    <div className="flex h-16 justify-between">
                                        <div className="flex">
                                            {/* LOGO START */}
                                            <div className="flex flex-shrink-0 items-center">
                                                <Link to={'/'} onClick={() => isCurrentPage(navigation, null)}>
                                                    <img className="block h-6 w-auto lg:hidden" src="/img/logo/logo-black.png" alt="refundr" />
                                                </Link>
                                                <Link to={'/'} onClick={() => isCurrentPage(navigation, null)}>
                                                    <img className="hidden h-6 w-auto lg:block" src="/img/logo/logo-black.png" alt="refundr" />
                                                </Link>
                                            </div>
                                            {/* LOGO END */}

                                            {/* SITE NAV START */}
                                            <div className="hidden sm:-my-px sm:ml-20 sm:flex sm:space-x-8">
                                                {navigation.map((item) =>
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => isCurrentPage(navigation, item.name)}
                        className={classNames(
                          item.current ?
                          'border-indigo-500 text-gray-900' :
                          'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}>

                                                        {item.name}
                                                    </Link>
                      )}
                                            </div>
                                            {/* SITE NAV END */}
                                        </div>

                                        {/* USER NAV START */}
                                        {user ?
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                                <div className="pr-6">
                                                    <ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} />
                                                </div>
                                                <Menu as="div" className="relative ml-3">
                                                    <div>
                                                        <Menu.Button
                          className="rounded-full relative flex max-w-xs items-center bg-white text-sm font-semibold text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                            <span className="absolute -inset-1.5" />
                                                            <span className="sr-only">Open user menu</span>
                                                            Menu
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">

                                                        <Menu.Items
                          className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <Menu.Item key="profile">
                                                                {({ active }) =>
                            <Link
                              to={`/feedback`}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}>

                                                                        Share feedback
                                                                    </Link>}

                                                            </Menu.Item>
                                                            <hr className="border-t-1 my-2 border-gray-200" />
                                                            <Menu.Item key="sign-out">
                                                                <Form action="/logout" method="POST">
                                                                    <button className="px-4 py-2 text-sm text-gray-700" type="submit">
                                                                        Logout
                                                                    </button>
                                                                </Form>
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div> :

                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                                <Link to="/login" className="block px-4 py-2 text-sm font-semibold text-gray-700">
                                                    Log&nbsp;in
                                                </Link>
                                            </div>}


                                        {/* USER NAV END */}

                                        {/* NAV ICON START */}
                                        <div className="-mr-2 flex items-center sm:hidden">
                                            <Disclosure.Button
                      className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                <span className="absolute -inset-0.5" />
                                                <span className="sr-only">Open main menu</span>
                                                {open ?
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> :

                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}

                                            </Disclosure.Button>
                                        </div>
                                        {/* NAV ICON END */}
                                    </div>
                                </div>

                                {/* MOBILE MENU START */}
                                <Disclosure.Panel className="sm:hidden">
                                    {/* MOBILE NAV START */}
                                    <div className="py-2 pl-2">
                                        <ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} />
                                    </div>
                                    <div className="space-y-1 pb-3 pt-2">
                                        {navigation.map((item) =>
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ?
                      'border-indigo-500 bg-indigo-50 text-indigo-700' :
                      'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                      'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}>

                                                {item.name}
                                            </Disclosure.Button>
                  )}
                                    </div>
                                    {/* MOBILE NAV END */}

                                    {/* MOBILE USER NAV START */}
                                    {user ?
                <div className="border-t border-gray-200 pb-3 pt-4">
                                            <div className="flex items-center px-4">
                                                <div className="ml-3">
                                                    <div className="text-base font-medium text-gray-800">
                                                        Menu
                                                    </div>
                                                </div>
                                                <button
                      type="button"
                      className="rounded-full relative ml-auto flex-shrink-0 bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">

                                                    <span className="absolute -inset-1.5" />
                                                    <span className="sr-only">View notifications</span>
                                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                            <div className="mt-3 space-y-1">
                                                <Form action="/logout" method="POST">
                                                    <button className="block px-4 py-2 text-base font-medium text-gray-500" type="submit">
                                                        Logout
                                                    </button>
                                                </Form>
                                            </div>
                                        </div> :

                <div className="border-t border-gray-200 pb-3 pt-4">
                                            <Link to="/login" className="block px-4 py-2 text-base font-medium text-gray-500">
                                                Log&nbsp;in
                                            </Link>
                                        </div>}

                                    {/* MOBILE USER NAV END */}
                                </Disclosure.Panel>
                                {/* MOBILE MENU END */}
                            </>}

                    </Disclosure>
                    {/* NAV END */}

                    {/* MAIN DIV START */}
                    <div className="min-h-full">
                        {/*<div className="min-h-full bg-[#F0F0F0] py-5">*/}
                        {/*<div className="min-h-full bg-[#132029] py-5">*/}
                        {/* <div className="py-10 min-h-full bg-[#132029]"> */}
                        <main>
                            <div className="mx-auto max-w-3xl px-6 sm:px-0">
                                <nav className="flex" aria-label="Breadcrumb">
                                    <ol role="list" className="flex items-center space-x-4">
                                        {matches.
                    filter(
                      (match) =>
                      // @ts-ignore
                      match.handle && match.handle.breadcrumb
                    ).
                    map((match, index) =>
                    // @ts-ignore
                    <li key={index}>{match.handle.breadcrumb(match)}</li>
                    )}
                                    </ol>
                                </nav>
                            </div>
                            <Outlet />
                        </main>
                    </div>
                    {/* FOOTER */}
                    {/*<div className="mx-auto max-w-2xl px-6 text-center sm:px-0 border-t border-zinc-200 text-gray-400">*/}
                    {/*	<div className="py-5">*/}
                    {/*		Hello World*/}
                    {/*	</div>*/}
                    {/*</div>*/}
                    {/* MAIN DIV END */}
                </div>
            </div>
            <div>
                <Toaster
          position="top-center"
          reverseOrder={false} />

            </div>
            <EpicProgress />
        </Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
            <HoneypotProvider {...data.honeyProps}>
                <App />
            </HoneypotProvider>
        </AuthenticityTokenProvider>);

}

export default AppWithProviders;

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints();
  const requestInfo = useRequestInfo();
  const optimisticMode = useOptimisticThemeMode();
  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode;
  }
  return requestInfo.userPrefs.theme ?? hints.theme;
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === '/');

  // const themeFetcher = fetchers.find(
  //     (f) => f.formAction === '/resources/theme-switch',
  // )

  if (themeFetcher && themeFetcher.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema
    });
    if (submission.status === 'success') {
      return submission.value.theme;
    }
  }
}

function ThemeSwitch({ userPreference }: {userPreference?: Theme | null;}) {
  const fetcher = useFetcher<typeof action>();
  const requestInfo = useRequestInfo();

  const [form] = useForm({
    id: 'theme-switch',
    // @ts-ignore
    lastResult: fetcher.data?.result
  });

  const optimisticMode = useOptimisticThemeMode();
  const mode = optimisticMode ?? userPreference ?? 'system';
  const nextMode = mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system';
  const modeLabel = {
    light:
    <Icon name="sun" className="text-black">
                <span className="sr-only">Light</span>
            </Icon>,

    dark:
    <Icon name="moon" className="text-black">
                <span className="sr-only">Dark</span>
            </Icon>,

    system:
    <Icon name="laptop" className="text-black">
                <span className="sr-only">System</span>
            </Icon>

  };

  return (
    <fetcher.Form
      method="POST"
      {...getFormProps(form)}
      action="/">

            <ServerOnly>
                {() =>
        <input type="hidden" name="redirectTo" value={requestInfo.path} />}

            </ServerOnly>
            <input type="hidden" name="theme" value={nextMode} />
            <div className="flex gap-2">
                <button
          type="submit"
          className="flex h-8 w-8 cursor-pointer items-center justify-center">

                    {modeLabel[mode]}
                </button>
            </div>
        </fetcher.Form>);

}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
            <GeneralErrorBoundary />
        </Document>);

}